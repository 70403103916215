import axios from "../../api/axios";
export const sendEmail = async (email, key) => {
  try {
    console.log("Sending out email");
    const response = await axios.post(`/sendEmail`, {
      email,
      key,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error("Error sending email");
  }
};
