import React, { useState, useEffect } from "react";
//Components
import ContactForm from "./components/ContactForm";
import Dropdown from "./components/Dropdown";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";

const industryOptions = [
  "Banking / Trust",
  "Business Services / Consulting",
  "Data Processing Services",
  "Education",
  "Financial Services",
  "Healthcare",
  "Insurance",
  "Life Sciences / Pharma",
  "Manufacturing",
  "Media / Advertising",
  "Medicare / Medicaid",
  "Retail / Wholesale",
  "SEC",
  "Software / Technology",
  "State or Local Government",
  "Telecommunications",
  "Transportation",
  "Utilities & Energy",
];
const states = [
  "Select State...",
  "No",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Indiana",
  "Iowa",
  "Maine",
  "Massachusetts",
  "Montana",
  "Nevada",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
];
const stateMachine = {
  initial: "Question1",
  states: {
    Question1: {
      on: {
        YES: "Question2",
        NO: "Question2",
      },
    },
    Question2: {
      on: {
        Education: "Question4",
        "Software / Technology": "Question4",
        "Life Sciences / Pharma": "Question4",
        DEFAULT: "Question3",
      },
    },
    Question3: {
      on: {
        YES: "Question4",
        NO: "Question6",
      },
    },
    Question4: {
      on: {
        YES: "ContactForm",
        NO: "Question5",
      },
    },
    Question5: {
      on: {
        YES: "ContactForm",
        NO: "",
      },
    },
    Question6: {
      on: {
        "Medical history, prescriptions, healthcare treatment": "ContactForm",
        NO: "Question8",
        DEFAULT: "Question7",
      },
    },
    Question7: {
      on: {
        YES: "ContactForm",
        NO: "Question9",
      },
    },
    Question8: {
      on: {
        YES: "ContactForm",
        NO: "Question14",
      },
    },
    Question9: {
      on: {
        NO: "Question10",
        DEFAULT: "ContactForm",
      },
    },
    Question10: {
      on: {
        Legal: "ContactForm",
        "Data processing": "ContactForm",
        Insurance: "ContactForm",
        DEFAULT: "Question11",
      },
    },
    Question11: {
      on: {
        YES: "Question12",
        NO: "Question13",
      },
    },
    Question12: {
      on: {
        YES: "ContactForm",
        NO: "ContactForm",
      },
    },
    Question13: {
      on: {
        No: "Question14",
        DEFAULT: "ContactForm",
      },
    },
    Question14: {
      on: {
        YES: "ContactForm",
        NO: "Question15",
      },
    },
    Question15: {
      on: {
        YES: "ContactForm",
        NO: "ContactForm",
      },
    },
    ContactForm: {
      on: {
        DEFAULT: "ContactForm",
      },
    },
  },
};
function Form({ hubspotutk, currentURL }) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");

  const [visitedQuestions, setVisitedQuestions] = useState(["Question1"]);
  const [backDisabled, setBackDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [question, setQuestion] = useState(stateMachine.initial);

  const [answers, setAnswers] = useState({});
  //Dropdown states
  const [industryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  const [statesDropdownOpen, setStatesDropdownOpen] = useState(false);

  const handleAnswer = (answer) => {
    //Closing dropdowns
    setIndustryDropdownOpen(false);
    setStatesDropdownOpen(false);
    const currentState = stateMachine.states[question];
    let nextState = currentState.on[answer] || currentState.on.DEFAULT;

    setAnswers((prev) => ({ ...prev, [question]: answer }));

    //Question 5 logic
    if (question === "Question5") {
      if (answer === "YES") {
        nextState = "ContactForm";
      } else if (
        answer === "NO" &&
        (answers["Question2"] === "SEC" ||
          answers["Question2"] === "State or Local Government")
      ) {
        nextState = "ContactForm";
      } else {
        nextState = "Question8";
      }
    }
    //Question 8 logic
    if (
      question === "Question8" &&
      answer === "YES" &&
      ["Software / Technology", "Insurance", "Telecommunications"].includes(
        answers["Question2"]
      )
    ) {
      nextState = "ContactForm";
    } else if (question === "Question8") {
      nextState = "Question14";
    }

    //--- Updating visited questions ---
    if (!visitedQuestions.includes(nextState)) {
      const currentQuestionIndex = visitedQuestions.indexOf(question);
      if (currentQuestionIndex !== -1) {
        const arrayWithCurrentQuestion = visitedQuestions.slice(
          0,
          currentQuestionIndex + 1
        );
        setVisitedQuestions([...arrayWithCurrentQuestion, nextState]);
      } else {
        setVisitedQuestions([...visitedQuestions, nextState]);
      }
    }
    setQuestion(nextState);
  };

  const renderQuestion = () => {
    switch (question) {
      case "Question1":
        return (
          <div className="question-card">
            <p>Does your company leverage artificial intelligence (AI)?</p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question1"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question1"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question2":
        return (
          <div className="question-card">
            <p>In what industry does your organization operate?</p>
            <Dropdown
              setOpen={setIndustryDropdownOpen}
              isOpen={industryDropdownOpen}
              options={industryOptions}
              handleAnswer={handleAnswer}
              defaultOption={answers["Question2"] ? answers["Question2"] : ""}
            />
          </div>
        );
      case "Question3":
        return (
          <div className="question-card">
            <p>
              Does your company work with the federal government, DoD, or
              federal contractors?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question3"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question3"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question4":
        return (
          <div className="question-card">
            <p>
              Does your institution receive federal funding or research grants?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => {
                  handleAnswer("YES");
                }}
                className={`${answers["Question4"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => {
                  handleAnswer("NO");
                }}
                className={`${answers["Question4"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question5":
        return (
          <div className="question-card">
            <p>Does it handle controlled unclassified information (CUI)?</p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question5"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question5"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question6":
        return (
          <div className="question-card">
            <p>
              Does your company process, store, or transmit any of the following
              types of information - or work with companies that do?
            </p>
            <div className="multiple-choice">
              <button
                type="button"
                onClick={() => handleAnswer("Full names, address, DOB, SSN")}
                className={`${
                  answers["Question6"] === "Full names, address, DOB, SSN" &&
                  "selected"
                }`}
              >
                Full names, address, DOB, SSN
              </button>
              <button
                type="button"
                onClick={() =>
                  handleAnswer("Credit card numbers, payment info")
                }
                className={`${
                  answers["Question6"] ===
                    "Credit card numbers, payment info" && "selected"
                }`}
              >
                Credit card numbers, payment info
              </button>

              <button
                type="button"
                onClick={() => handleAnswer("Personal income, credit history")}
                className={`${
                  answers["Question6"] === "Personal income, credit history" &&
                  "selected"
                }`}
              >
                Personal income, credit history
              </button>
              <button
                type="button"
                onClick={() =>
                  handleAnswer(
                    "Medical history, prescriptions, healthcare treatment"
                  )
                }
                className={`${
                  answers["Question6"] ===
                    "Medical history, prescriptions, healthcare treatment" &&
                  "selected"
                }`}
              >
                Medical history, prescriptions, healthcare treatment
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question6"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question7":
        return (
          <div className="question-card">
            <p>
              Does your company provide consumer credit reports, give consumer
              data to credit agencies, or offer loans or credit to consumers?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question7"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question7"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question8":
        return (
          <div className="question-card">
            <p>
              Is your company publicly traded, planning to go public, or provide
              financial services to publicly traded companies?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question8"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question8"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question9":
        return (
          <div className="question-card">
            <p>Does your organization provide any of the following services?</p>
            <div className="multiple-choice">
              <button
                type="button"
                onClick={() => handleAnswer("Payroll")}
                className={`${
                  answers["Question9"] === "Payroll" && "selected"
                }`}
              >
                Payroll
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Debt collections")}
                className={`${
                  answers["Question9"] === "Debt collections" && "selected"
                }`}
              >
                Debt collections
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Financing, loans, or mortgages")}
                className={`${
                  answers["Question9"] === "Financing, loans, or mortgages" &&
                  "selected"
                }`}
              >
                Financing, loans, or mortgages
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Real estate settlement")}
                className={`${
                  answers["Question9"] === "Real estate settlement" &&
                  "selected"
                }`}
              >
                Real estate settlement
              </button>
              <button
                type="button"
                onClick={() =>
                  handleAnswer("Investment and/or financial consultation")
                }
                className={`${
                  answers["Question9"] ===
                    "Investment and/or financial consultation" && "selected"
                }`}
              >
                Investment and/or financial consultation
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question9"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question10":
        return (
          <div className="question-card">
            <p>Does your organization provide any of the following services?</p>
            <div className="multiple-choice">
              <button
                type="button"
                onClick={() => handleAnswer("Legal")}
                className={`${answers["Question10"] === "Legal" && "selected"}`}
              >
                Legal
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Data processing")}
                className={`${
                  answers["Question10"] === "Data processing" && "selected"
                }`}
              >
                Data processing
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Third-party administration")}
                className={`${
                  answers["Question10"] === "Third-party administration" &&
                  "selected"
                }`}
              >
                Third-party administration
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("Insurance")}
                className={`${
                  answers["Question10"] === "Insurance" && "selected"
                }`}
              >
                Insurance
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("No")}
                className={`${answers["Question10"] === "No" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question11":
        return (
          <div className="question-card">
            <p>Does your company have international operations?</p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question11"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question11"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question12":
        return (
          <div className="question-card">
            <p>
              Does your company collect or process personal information on
              residents within the EU?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question12"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question12"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question13":
        return (
          <div className="question-card">
            <p>
              Does your company collect or process personal information on
              residents from any of the following states?
            </p>
            <Dropdown
              setOpen={setStatesDropdownOpen}
              isOpen={statesDropdownOpen}
              options={states}
              handleAnswer={handleAnswer}
              defaultOption={answers["Question13"] ? answers["Question13"] : ""}
            />
          </div>
        );
      case "Question14":
        return (
          <div className="question-card">
            <p>
              Does your organization accept, transmit, or store any credit card
              data?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question14"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question14"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Question15":
        return (
          <div className="question-card">
            <p>
              Does your company work in healthcare or do business with medical
              facilities, service providers, or BAs?
            </p>
            <div className="questions">
              <button
                type="button"
                onClick={() => handleAnswer("YES")}
                className={`${answers["Question15"] === "YES" && "selected"}`}
              >
                Yes
              </button>
              <button
                type="button"
                onClick={() => handleAnswer("NO")}
                className={`${answers["Question15"] === "NO" && "selected"}`}
              >
                No
              </button>
            </div>
          </div>
        );
      case "Processing":
        return <p>We're processing the results...</p>;
      default:
        return (
          <ContactForm
            answers={answers}
            hubspotutk={hubspotutk}
            currentURL={currentURL}
            setQuestion={setQuestion}
            email={email}
            setEmail={setEmail}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            company={company}
            setCompany={setCompany}
          />
        );
    }
  };
  const goBack = () => {
    //console.log("----------------- BACK ---------------");
    //console.log("Visited questions:", visitedQuestions);
    const currentQuestionIndex = visitedQuestions.indexOf(question);
    //console.log("Current question index:", currentQuestionIndex);
    if (currentQuestionIndex === 0) {
      return;
    }
    if (currentQuestionIndex === -1) {
      setQuestion(visitedQuestions[visitedQuestions.length - 1]);
    } else {
      const previousQuestion = visitedQuestions[currentQuestionIndex - 1];

      setQuestion(previousQuestion);
    }
  };
  const goNext = () => {
    //console.log("----------------- NEXT ---------------");
    //console.log("Visited questions:", visitedQuestions);
    const currentQuestionIndex = visitedQuestions.indexOf(question);
    if (currentQuestionIndex === -1) {
      return;
    }
    const nextQuestion = visitedQuestions[currentQuestionIndex + 1];
    if (nextQuestion) {
      setQuestion(nextQuestion);
    }
  };

  useEffect(() => {
    const currentIndex = visitedQuestions.indexOf(question);
    // ---- Next Button Logic ----
    if (currentIndex === visitedQuestions.length - 1 || currentIndex === -1) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }

    // ---- Back Button Logic ----
    if (currentIndex === 0) {
      setBackDisabled(true);
    } else {
      setBackDisabled(false);
    }
  }, [question]);

  return (
    <div
      className={`flex items-center justify-center w-full h-screen bg-[#ffd200] relative px-6 `}
    >
      <div
        className={`absolute left-0 bottom-[-150%] w-full h-[150%] bg-[#ffd200]  ${
          industryDropdownOpen || statesDropdownOpen ? "!block" : "hidden"
        }
          `}
      />
      <form id="isp_compliance_form" className="flex justify-center w-full">
        {renderQuestion()}
      </form>

      {industryDropdownOpen || statesDropdownOpen ? null : (
        <div className={`navigation-buttons `}>
          <button
            type="button"
            onClick={backDisabled ? null : goBack}
            className={`${backDisabled && "cursor-not-allowed"}`}
          >
            <FaArrowUp className="mx-auto" />
          </button>
          <button
            type="button"
            onClick={nextDisabled ? null : goNext}
            className={`${nextDisabled && "cursor-not-allowed"}`}
          >
            <FaArrowDown className="mx-auto" />
          </button>
        </div>
      )}
    </div>
  );
}

export default Form;
