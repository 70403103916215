import React from "react";
//Components
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
const Dropdown = ({
  setOpen,
  isOpen,
  options,
  handleAnswer,
  defaultOption,
}) => {
  return (
    <div className="relative ">
      <div
        className="dropdown"
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <p>{defaultOption ? defaultOption : "Select an option..."}</p>

        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>

      {isOpen && (
        <div className="absolute w-full ">
          {options.map((option, index) => (
            <p
              key={index}
              className="px-4 py-1 mt-1 border border-black rounded-md cursor-pointer bg-[#ecc826]"
              onClick={() => handleAnswer(option)}
            >
              {option}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
