import axios from "../../api/axios";
import { questions } from "../../data/questions";
export const submitForm = async (
  token,
  email,
  firstName,
  lastName,
  company,
  currentURL,
  answers
) => {
  try {
    let answersFormated = ``;
    const answerKeys = Object.keys(answers);
    for (let question of answerKeys) {
      answersFormated += `${questions[question]}: ${answers[question]}<br>`;
    }
    console.log("Answers formated:", answersFormated);
    const response = await axios.post("/createSubmission", {
      hutk: token,
      email: email,
      pageUrl: currentURL,
      firstName: firstName,
      lastName: lastName,
      company: company,
      answers: answersFormated,
    });

    console.log("Form submitted successfully:", response.data);
  } catch (error) {
    console.error("Error submitting form:", error);
    throw new Error("Error submitting form");
  }
};
