import axios from "../../api/axios";
//Data
import { questions } from "../../data/questions";
export async function createNote(contactId, answers, endpoint) {
  try {
    let note = `<b>Compliance Checker Results:</b><br><b>Recomended Framework:</b> ${endpoint}<br><br><br><b>Details:</b><br>`;
    const answerKeys = Object.keys(answers);
    for (let question of answerKeys) {
      note += `${questions[question]}: ${answers[question]}<br>`;
    }

    const body = {
      contactId,
      noteBody: note,
    };

    const response = await axios.post(`/createNote`, body);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error creating note");
  }
}
