import axios from "../../api/axios";
export async function searchForContactByEmail(email) {
  try {
    const response = await axios.get(`/getCustomer/${email}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Error searching for contact");
  }
}
