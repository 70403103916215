export const questions = {
  Question1: "Does your company leverage artificial intelligence (AI)?",
  Question2: "In what industry does your organization operate?",
  Question3:
    "Does your company work with the federal government, DoD, orfederal contractors?",
  Question4:
    "Does your institution receive federal funding or research grants?",
  Question5: "Does it handle controlled unclassified information (CUI)?",
  Question6:
    "Does your company process, store, or transmit any of the following types of information - or work with companies that do?",
  Question7: `Does your company provide consumer credit reports, give consumer data to credit agencies, or offer loans or credit to consumers?`,
  Question8:
    "Is your company publicly traded, planning to go public, or provide financial services to publicly traded companies?",
  Question9: "Does your organization provide any of the following services?",
  Question10: "Does your organization provide any of the following services?",
  Question11: "Does your company have international operations?",
  Question12:
    "Does your company collect or process personal information on residents within the EU?",
  Question13:
    "Does your company collect or process personal information on residents from any of the following states?",
  Question14:
    "Does your organization accept, transmit, or store any credit card data?",
  Question15:
    "Does your company work in healthcare or do business with medical facilities, service providers, or BAs?",
};
