import { useEffect, useState } from "react";
import Form from "./components/Form/Index.js";
import { Helmet } from "react-helmet";
function App() {
  //? Grabbing the hubspotutk value from the ISP website
  const [hubspotutk, setHubspotutk] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  useEffect(() => {
    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.event === "hubspot_tracking_code") {
          console.log("Hubspotutk received from ISP website:", data.hubspotutk);
          console.log(
            "Current URL received from ISP website:",
            data.currentURL
          );
          const hubspotutk = data.hubspotutk;
          const currentURL = data.currentURL;
          setHubspotutk(hubspotutk);
          setCurrentURL(currentURL);
        }
      } catch (e) {
        console.error("Error in retrieving data from ISP website", e);
      }
    };

    window.addEventListener("message", handleMessage);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="googlebot" content="noindex" />
        <meta name="googlebot" content="indexifembedded" />
        <link
          rel="icon"
          href="https://www.ispartnersllc.com/wp-content/uploads/cropped-fav-192x192.png"
          sizes="192x192"
        ></link>
        <link
          rel="icon"
          href="https://www.ispartnersllc.com/wp-content/uploads/cropped-fav-32x32.png"
          sizes="32x32"
        />
      </Helmet>
      <Form hubspotutk={hubspotutk} currentURL={currentURL} />
    </>
  );
}

export default App;
